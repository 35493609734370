import { Card, SelectInput, Switch, PrimaryButton } from '@sympaoy/pomegranate'
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import { findSelectedInput } from '../../utils/findSelectedInput'

const InputContainer = styled.div`
  width: 100%;
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`

const identityProviders = [
  {
    id: 'empty',
    label: '',
    qaId: 'organization__iam-card__technical-profile__empty',
  },
  {
    id: 'commonaad',
    label: 'Entra ID (Azure AD)',
    qaId: 'organization__iam-card__technical-profile__commonaad',
  },
  {
    id: 'liseberg',
    label: 'Liseberg',
    qaId: 'organization__iam-card__technical-profile__liseberg',
  },
  {
    id: 'google',
    label: 'Google ID',
    qaId: 'organization__iam-card__technical-profile__google',
  },
  {
    id: '',
    label: 'Local (only non-SSO)',
    qaId: 'organization__iam-card__technical-profile__local',
  },
]

// TechDebt AB#109989 Migrate to Typescript
const IAMCard = ({ qaIdPrefix, configuration, onSave }) => {
  return (
    <Formik
      initialValues={
        configuration || {
          enabled: false,
          technicalProfileReferenceId: 'empty',
        }
      }
      onSubmit={async (values, helpers) => {
        await onSave(values)
        helpers.resetForm({ values })
      }}
    >
      {(formikProps) => {
        const handleChange = async (field, value) => {
          await formikProps.setFieldValue(field, value)
          formikProps.handleChange(field)
        }

        return (
          <Form>
            <Card title="IAM" qaId={`${qaIdPrefix}__iam-card`}>
              <Switch
                qaId="organization__iam-card__azure-enabled"
                ariaLabel="Enabled"
                isSelected={formikProps.values.enabled}
                onChange={(value) => handleChange('enabled', value)}
                name="enabled"
                inputProps={{ 'aria-label': 'controlled' }}
              >
                Enabled
              </Switch>
              <InputContainer>
                <SelectInput
                  value={findSelectedInput(
                    identityProviders,
                    formikProps.values.technicalProfileReferenceId
                  )}
                  disabled={!formikProps.values.enabled}
                  items={identityProviders}
                  onChange={(value) =>
                    handleChange('technicalProfileReferenceId', value.id)
                  }
                  name="technicalProfileReferenceId"
                  qaId="organization__iam-card__technical-profile"
                  required
                  text="Identity Provider"
                />
              </InputContainer>
              <ButtonContainer>
                <PrimaryButton
                  // TechDebt AB#109991 Replace with React Hook Form and apply zod Validation
                  disabled={
                    !formikProps.dirty ||
                    (formikProps.values.technicalProfileReferenceId ===
                      'empty' &&
                      formikProps.values.enabled === true)
                  }
                  isLoading={formikProps.isSubmitting}
                  qaId="organization__iam-card__save"
                  onPress={formikProps.submitForm}
                >
                  Save
                </PrimaryButton>
              </ButtonContainer>
            </Card>
          </Form>
        )
      }}
    </Formik>
  )
}

IAMCard.propTypes = {
  qaIdPrefix: PropTypes.string.isRequired,
  configuration: PropTypes.shape({
    enabled: PropTypes.bool,
    technicalProfileReferenceId: PropTypes.string,
  }),
  onSave: PropTypes.func.isRequired,
}

IAMCard.defaultProps = {
  configuration: {
    enabled: false,
  },
}

export default IAMCard

type InputOption = { id: string | number; label: string; qaId: string }

/**
 * Finds the selected option by ID.
 *
 * @param {InputOption[]} options - Array of options objects.
 * @param {string | number} selectedId - The ID of the selected option.
 * @returns {InputOption|undefined} - The matching option object, or undefined if not found.
 */
export const findSelectedInput = (
  options: InputOption[],
  selectedId: string | number
): InputOption | undefined => {
  if (!Array.isArray(options) || selectedId == null) {
    return undefined
  }
  return options.find((idp) => idp.id === selectedId)
}
